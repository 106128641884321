import React, { useState } from "react";
import { Col, Row, InputGroup, InputGroupText, Input, Button } from "reactstrap";
import styled from "styled-components";
import NotfoundImage from "../../assets/images/not-found.png";
import { Link, useParams } from "react-router-dom";
import { Search } from "react-feather";
import { useNavigate } from "react-router-dom";

const NotFoundPage = () => {

  const [search, setSearch] = useState("");
  const navigate = useNavigate()
  const handleFilter = () => {
    window.location.href = "https://mycoa.io/search/?query=" + search;
  };

  return (
    <div className="page-wrapper">
      <NotFoundWrapper className="py-6">
        <Row className="mx-0 align-items-center">
          <Col lg="6" className="text-center mb-2 mb-lg-0">
            <img
              src={NotfoundImage}
              alt="not found"
              className="w-100"
              style={{ maxWidth: "600px" }}
            />
          </Col>
          <Col lg="6">
            <h1 className="page-title">
              Page Not Found. It Looks Like What You’re Looking For Has Moved.
            </h1>
            <SearchWrapper className="">
              <div className="search-box  ">
                <InputGroup>
                <Input
                  value={search}
                  onChange={(e) => { setSearch(e.target.value)}}
                  onKeyDown={(e) => { if(e.key === "Enter"){handleFilter()}}}
                  placeholder="Search"
                  />
                  <InputGroupText
                    onClick={() => {
                      handleFilter();
                    }}
                  >
                    <Search />
                  </InputGroupText>
                </InputGroup>
              </div>
            </SearchWrapper>

            <div
              className="d-flex align-items-center justify-content-start flex-wrap"
              style={{ gap: "15px" }}
            >
              <Button
                onClick={() => {
                  navigate(-1);
                }}
                color="primary" outline>
                Previous Page
              </Button>
              <Link to="/">
                <Button color="primary" outline style={{ minWidth: "211px" }}>
                  Home Page
                </Button>
              </Link>
            </div>
          </Col>
        </Row>
      </NotFoundWrapper>
    </div>
  );
};

export default NotFoundPage;

export const NotFoundWrapper = styled.section`
  @media (min-width: 1025px) {
    .page-title {
      font-size: 50px;
    }
  }
`;

export const SearchWrapper = styled.div`
  max-width: 698px;
  width: 100%;

  margin-right: auto;
  margin-top: 25px;
  margin-bottom: 40px;
  .search-box {
    .input-group {
      input {
        border-width: 2px;
        height: 3.6rem;
        border-color: var(--primary);
      }

      .input-group-text {
        background: var(--primary);
        padding-left: 30px;
        padding-right: 30px;
        color: #fff;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        font-weight: 600;
        border-width: 2px;
        border-left: unset;
        border-color: var(--primary);
        cursor: pointer;
        transition: background 300ms ease, border 300ms ease;

        &:hover {
          background-color: var(--secondary);
          border-color: var(--secondary);
        }
      }
    }
  }
`;
