import axios from "axios";

export const trackVisit = async (post,user) => {
  // console.log({post});
  return await axios.post(`${process.env.REACT_APP_API_URL}/analytics/track`,{resource_id:post?._id,user_id:user});
 
};

export const updateTrackVisit = (analytics_id,user_id) => {
  // console.log({analytics_id});
  // alert();
  
  // axios.put(`${process.env.REACT_APP_API_URL}/analytics/updatetrack`,{track_id:analytics_id,user_id}).then((response) => {
  //   console.log(response,'related_posts')
  //   return response.data.data;
  // });

  navigator.sendBeacon(`${process.env.REACT_APP_API_URL}/analytics/updatetrack`, {track_id:analytics_id,user_id});
 
};


