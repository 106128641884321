const menuItems = [
  {
    title: "COA INITIATIVES",
    link: "#",
    rel: "nofollow",
    submenu: [
      {
        title: "COA Administrators’ Network (CAN)",
        link: "https://coacan.org/",
        target: "_self",
      },
      {
        title: "Community Oncology Pharmacy Association (COPA)",
        link: "https://coapharmacy.com/",
        target: "_self",
      },
      {
        title: "COA Patient Advocacy Network (CPAN)",
        link: "https://coaadvocacy.org/",
        target: "_self",
      },
      {
        title: "COA Fellows Initiative",
        link: "https://coafellows.org/",
        target: "_self",
      },

      {
        title: "Enhancing Oncology Model Support Network (EOM)",
        link: "https://communityoncology.org/coa-initatives/eom/",
        target: "_self",
      },
      {
        title: "National Cancer Treatment Alliance (NCTA)",
        link: "https://nctacancer.com/",
        target: "_self",
      },
      {
        title: "Oncology Medical Home (OMH)",
        link: "https://medicalhomeoncology.org/",
        target: "_self",
      },
    ],
  },
  {
    title: "EVENTS",
    link: "#",
    rel: "nofollow",
    submenu: [
      {
        title: "Community Oncology Conference",
        link: "/events/annual-conferences",
        target: "_self",
      },
      {
        title: "Payer Exchange Summit",
        link: "/events/payer-exchange-summits",
        target: "_self",
      },
      {
        title: "State of Community Oncology Briefing",
        link: "/events/state-of-community-oncology-briefings",
        target: "_self",
      },
      {
        title: "Advocacy Chats",
        link: "https://coaadvocacy.org/events/advocacy-chats/",
        target: "_self",
      },
      {
        title: "Advocacy Summit",
        link: "/events/advocacy-summits",
        target: "_self",
      },
      {
        title: "Partner Events",
        link: "/events/partner-events/",
        target: "_self",
      },
      {
        title: "Webinars",
        link: "/events/webinars/",
        target: "_self",
      },
      {
        title: "All COA Events",
        link: "/events/",
        target: "_self",
      },
    ],
  },
  {
    title: "GET INVOLVED",
    link: "#",
    rel: "nofollow",
    submenu: [
      {
        title: "Membership Benefits",
        link: "https://communityoncology.org/membership",
        target: "_self",
      },
      {
        title: "Join Now",
        link: "https://mycoa.io/join",
        target: "_self",
      },
    ],
  },
];

export default menuItems;
